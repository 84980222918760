import React, { useState } from "react";
import { ContentContainer, PostCard, BlogContent, Seo } from "../components";
import { graphql, Link } from "gatsby";

const Guides = props => {
  const { data } = props;
  const postCount = data.postsRemark.totalCount;
  const allPosts = data.postsRemark.edges;
  const allGames = data.gamesGroup.group;
  const defaultImage = data.defaultImage.fluid;

  const [currentPaginationPage, setCurrentPaginationPage] = useState(1);

  const maxPaginationPage = Math.ceil(data.postsRemark.totalCount / 4);
  const isFirst = currentPaginationPage === 1;
  const isLast = currentPaginationPage === maxPaginationPage;

  const handlePaginationChange = i => {
    if (i >= 0 && i <= postCount) {
      setCurrentPaginationPage(i);
    }
  };

  const emptyQuery = "";

  const [searchValue, setSearchValue] = useState(emptyQuery);
  const [filteredPosts, setFilteredPosts] = useState([]);

  const handleInputChange = event => {
    const query = event.target.value;
    filterBySearch(query);
    setSearchValue(query);
  };

  const handleGameClick = value => {
    filterBySearch(value);
    setSearchValue(value);
  };

  const filterBySearch = filterValue => {
    const posts = allPosts || [];

    const filteredData = posts.filter(post => {
      const { description, title, game } = post.node.frontmatter;
      return game.toLowerCase().includes(filterValue.toLowerCase());
    });

    setFilteredPosts(filteredData);
  };

  const hasSearchResults = filteredPosts && searchValue !== emptyQuery;
  const posts = hasSearchResults ? filteredPosts : allPosts;
  // get all tags from posts

  return (
    <ContentContainer>
      <Seo
        title="Gaming Posts"
        // image={seoImage}
        description="Page for guides, tutorial, or just notes."
      />
      <BlogContent>
        <div className="container">
          <section className="blog-content">
            {/* <div> */}
            {posts.map(({ node }, index) => (
              <PostCard
                key={index}
                to={node.frontmatter.slug}
                description={node.frontmatter.description}
                title={node.frontmatter.title}
                date={node.frontmatter.date}
                fluidImage={
                  node.frontmatter.image
                    ? node.frontmatter.image.childImageSharp.fluid
                    : defaultImage
                }
              />
            ))}
            {/* </div> */}

            {!hasSearchResults && (
              <div className="pagination">
                {!isFirst && (
                  <button
                    className="button"
                    onClick={e =>
                      handlePaginationChange(currentPaginationPage - 1)
                    }
                  ></button>
                )}

                {Array.from({ length: maxPaginationPage }, (_, index) => (
                  <button
                    className="button"
                    key={index}
                    // active={currentPaginationPage == index + 1 ? true : false}
                    onClick={e => handlePaginationChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}

                {!isLast && (
                  <button
                    className="button"
                    onClick={e =>
                      handlePaginationChange(currentPaginationPage + 1)
                    }
                  ></button>
                )}
              </div>
            )}
          </section>

          <section className="blog-sidebar">
            <div className="side-container">
              <form className="search-form">
                <input
                  className="search-field mr-sm-2"
                  type="search"
                  placeholder="Search Gaming"
                  aria-label="Search Gaming"
                  onChange={handleInputChange}
                  value={searchValue}
                />
              </form>
            </div>

            {/* <Categories /> */}
            <div className="side-container">
              <h1>Games</h1>
              {allGames.map((node, index) => (
                // <GameListItem>{node.fieldValue}</GameListItem>
                <li key={index}>
                  <button
                    className="link-button"
                    onClick={() => handleGameClick(node.fieldValue)}
                  >
                    {node.fieldValue.replace("-", " ")}
                  </button>
                </li>
              ))}
            </div>
          </section>
        </div>
      </BlogContent>
    </ContentContainer>
  );
};

export default Guides;

export const pageQuery = graphql`
  query {
    postsRemark: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fields: { collection: { eq: "guides" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM YYYY")
            game
            slug
          }
        }
      }
      totalCount
    }
    defaultImage: file(relativePath: { eq: "coding_default.jpg" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tagsGroup: allMdx(
      limit: 2000
      filter: { fields: { collection: { eq: "posts" } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
    categoryGroup: allMdx(
      limit: 2000
      filter: { fields: { collection: { eq: "posts" } } }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
    gamesGroup: allMdx(
      filter: { fields: { collection: { eq: "guides" } } }
      limit: 2000
    ) {
      group(field: frontmatter___game) {
        fieldValue
      }
    }
  }
`;
